
html, body {
    height: 100vh;
    margin: 0;
    padding: 0;
    color: var(--color-text);
    background-color: rgba(200, 200, 200, 0.1);
}

body:before {
    content: "";
    width: 50vw;
    height: calc(100vh - var(--featured-bottom-height));
    z-index: -1;
    position: fixed;
    top: 0;
    right: 0;
    background-image: url("miami.webp");
    background-size: cover;
    background-position: 100% 0%;
    background-repeat: no-repeat;
    transition: filter 0.5s;
    filter: opacity(0.0);
}

body.landing-page:before {
    filter: opacity(0.9);
}

.page {
    padding: 0 var(--page-margin-x);
}
